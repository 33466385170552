<template>
  <div>
    <div class="container">
      <div class="top">
        <img class="topimg" :src="require('@/assets/3.png')" alt="">
        <div class="orderinfo">
          <div class="userinfo">
            <div class="top-box">
              <van-image
                v-if="orderData.userHeadimgurl"
                round
                width="50px"
                height="50px"
                :src="orderData.userHeadimgurl"
              />
              <img :src="require('@/assets/default1.png')" v-else alt=""/>
              <span>{{ orderData.userNickName }}</span>
            </div>
            <div class="bottom-box">
              <span>{{ orderData.orderAddtime }}</span>
            </div>
          </div>
          <div class="praycomment">
            <van-notice-bar
              scrollable
              left-icon="volume-o"
              :text="orderData.orderPrayName + orderData.orderPrayComment"

            />
          </div>
        </div>
        <div class="title-box">
          <span>分享心愿，让您的亲友感受到这份祝福</span>
        </div>
      </div>
      <div class="button-box">
        <van-button class="left-btn" @click="goHome()">返回首页</van-button>
        <van-button class="right-btn">分享</van-button>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import { getQueryParam } from '@/utils/url'
import { getOrderById } from '@/api/modules/order'
import { Toast } from 'vant'

export default {
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  // 这里用于存放数据
  data () {
    return {
      orderId: getQueryParam('orderId') || '',
      orderData: {
        orderCurrentStatus: '',
        orderTempleId: '',
        orderPrayName: '',
        orderPrayComment: '',
        orderAddtime: '',
        userHeadimgurl: '',
        userNickName: '',
      }
    }
  },
//  计算属性 类似于data概念
  computed: {},
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {
    async getOrder () {
      if (this.orderId !== '') {
        const { data } = await getOrderById(this.orderId)
        console.log(data)
        this.orderData = data.data
      } else {
        Toast({
          message: '参数不正确，缺少订单id'
        })
      }
    },
    goHome(){
      this.$router.push('/home')
    }

  },
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {
    this.getOrder()
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {

  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column; /* 垂直布局 */
  justify-content: center;
  align-items: center;

  .top {
    display: flex; /* 设置为 flex 布局 */
    justify-content: center; /* 内容水平居中 */
    position: relative;
    width: 100vw;
    height: 308px;
    background: #b34341;
    padding-top: 80px;

    .topimg {
      display: inline-block;
      width: 231px;
      height: 231px;
    }

    .orderinfo {
      position: absolute;
      top: 81%;
      left: 50%; /* 设置左侧位置为容器的一半 */
      transform: translateX(-50%); /* 使用负的50%的宽度来居中 */
      width: 327px;
      height: 147px;
      background: #fdfdfd;

      .userinfo {
        display: flex;
        flex-direction: column;
        padding: 5px 20px;
        background: rgba(255, 222, 174, .2);

        .top-box {
          display: flex;
          align-items: center;

          img {
            width: 30px;
            height: 30px;
          }

          span {
            margin-left: 10px;
          }
        }

        .bottom-box {
          padding-top: 5px;

          span {
            font-size: 14px;
            color: rgba(0, 0, 0, .45);
            line-height: 5px;
          }
        }
      }

      .praycomment {
        .van-notice-bar {
          height: 64px;
          background-color: rgba(255, 222, 174, .2);
        }
      }
    }

    .title-box {
      position: absolute;
      top: 130%;
      height: 30px;
      left: 50%; /* 设置左侧位置为容器的一半 */
      transform: translateX(-50%); /* 使用负的50%的宽度来居中 */
      color: rgba(0, 0, 0, .45);
      text-align: center;
      white-space: nowrap;
    }
  }

  .button-box {
    display: flex;
    z-index: 200;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 54px;

    .left-btn {
      flex: 1;
      border: none;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-size: .373333rem;
      font-family: PingFangSC-Regular, PingFang SC sans-serif;
      font-weight: 400;
      background-color: #eee;
      color: rgba(0, 0, 0, .65);
    }

    .right-btn {
      flex: 1;
      border: none;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-size: .373333rem;
      font-family: PingFangSC-Regular, PingFang SC sans-serif;
      font-weight: 400;
      background: #b34341;
      color: #fff;
    }
  }

}


</style>
